<template>
    <div>
        <Toast />
        <div class="card">
            <h5>Emisión Multiple</h5>        
        </div>        
        <div class="card">
            <Steps :model="items" :readonly="true" />
        </div>

        <router-view v-slot="{Component}" :key="keyUpdate" :formData="condiciones" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete">
            <keep-alive>
                <component :is="Component" />
            </keep-alive>
        </router-view>

    </div>
</template>
<script>
// import MdsEmisionInmuebles from '../mds/EmisionInmuebles.vue';
import { CURRENT_APP_MODE } from '../../service/constants';

export default {
    data() {
        return {
            appMode: CURRENT_APP_MODE,
            keyUpdate: 0,
			complejo: this.$store.state.auth.currentAppCtx.complejo,            
			condiciones: {
				id: '',
				id_mov_plantilla: '0',
				id_cta_habiente_info: '',
				id_categoria: '',
				tasa_cambio: 7.82,
				fecha_emision: new Date(),
				cambiar_fecha_pago: true,
				fecha_pago: new Date(),
				Param1: '',
				Param2: '',
				Param3: '',
				Param4: '',
				Param5: '',
				Param6: '0',
                enviar_notificacion: false,
                cargosEmitidos: null
			},
            items: [{
                label: 'Selección de Inmuebles',
                to: '/mdsemision'
            },
            {
                label: 'Plan de Servicio',
                to: '/planes'
            },
            {
                label: 'Detalles de Emisión',
                to: '/detalles'
            },            
            {
                label: 'Revisión',
                to: '/revision'
            },
            {
                label: 'Resumen',
                to: '/resumen'
            }            
            ],
            formObject: {}
        }
    },
    methods: {
        nextPage(event) {
            this.condiciones.id = event.condiciones.id;
            this.condiciones.id_mov_plantilla = event.condiciones.id_mov_plantilla;
            this.condiciones.id_cta_habiente_info = event.condiciones.id_cta_habiente_info;
            this.condiciones.id_categoria = event.condiciones.id_categoria;
            this.condiciones.tasa_cambio = event.condiciones.tasa_cambio; 
            this.condiciones.fecha_emision = event.condiciones.fecha_emision;
            this.condiciones.cambiar_fecha_pago = event.condiciones.cambiar_fecha_pago;
            this.condiciones.fecha_pago = event.condiciones.fecha_pago;
            this.condiciones.Param1 = event.condiciones.Param1;
            this.condiciones.Param2 = event.condiciones.Param2;
            this.condiciones.Param3 = event.condiciones.Param3;
            this.condiciones.Param4 = event.condiciones.Param4;
            this.condiciones.Param5 = event.condiciones.Param5;
            this.condiciones.Param6 = event.condiciones.Param6;
            this.condiciones.enviar_notificacion = false;
            
            if(event.condiciones.cargosEmitidos) {
                this.condiciones.cargosEmitidos = event.condiciones.cargosEmitidos;
            }

            let path = this.items[event.pageIndex + 1].to;
            
            if((event.pageIndex + 1) == 3) { //Si es la pagina de revision se debe actualizar para que se recalcule
                this.keyUpdate++;
            }

            this.$router.push(path);
        },
        prevPage(event) {
            this.$router.push(this.items[event.pageIndex - 1].to);
        },
        complete() {
            this.$toast.add({severity:'success', summary:'Order submitted', detail: 'Dear, ' + this.formObject.firstname + ' ' + this.formObject.lastname + ' your order completed.'});
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}
</style>